/* eslint-disable no-dupe-keys */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import Slider from "react-slick";
import Fruit from "../../Asset/images/fruit.png";
import { Modal, Box } from "@mui/material";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

import Icon1 from "../../Asset/images/icon_calibre.png";
import Icon2 from "../../Asset/images/icon_forma.png";
import Icon3 from "../../Asset/images/icon_textura.png";
import Icon4 from "../../Asset/images/icon_color.png";

import product1 from "../../Asset/images/product1.png";
import product2 from "../../Asset/images/product2.png";
import product3 from "../../Asset/images/product3.png";
import product4 from "../../Asset/images/product4.png";
import product5 from "../../Asset/images/product5.png";
import product6 from "../../Asset/images/product6.png";
import product8 from "../../Asset/images/product8.png";
import product9 from "../../Asset/images/product9.png";
import product10 from "../../Asset/images/product10.png";
import dayjs from "dayjs";

import melon3 from "../../Asset/images/melon3.png";
import plant from "../../Asset/svg/icone_handholdingplant.svg";
import carrot from "../../Asset/images/icone_handholdingcarrot.png";
import icone_fruit from "../../Asset/images/icone_fruitbasket.png";

import "./fruitsslider.scss";
// Static data
const fruitsData = [
  {
    title: "Meloa Cantaloupe",
    // description: "Some description",
    categories: [
      { color: "#E0A00E", imageUrl: melon3, id: "category1" },
      { color: "#EDB405", imageUrl: plant, id: "category2" },
      { color: "#A7D379", imageUrl: carrot, id: "category3" },
      { color: "#593A18", imageUrl: icone_fruit, id: "category4" },
    ],
    url: product8,
  },
  {
    title: "Meloa Gália",
    // description: "Some description",
    categories: [
      { color: "#E0A00E", imageUrl: melon3, id: "category1" },
      { color: "#EDB405", imageUrl: plant, id: "category2" },
      { color: "#A7D379", imageUrl: carrot, id: "category3" },
      { color: "#593A18", imageUrl: icone_fruit, id: "category4" },
    ],
    url: product9,
  },
  {
    title: "Melão Branco",
    // description: "Some description",
    categories: [
      { color: "#E0A00E", imageUrl: melon3, id: "category1" },
      { color: "#EDB405", imageUrl: plant, id: "category2" },
      { color: "#A7D379", imageUrl: carrot, id: "category3" },
      { color: "#593A18", imageUrl: icone_fruit, id: "category4" },
    ],
    url: product3,
  },
  {
    title: "Melância Riscada",
    description: "c/ micro sementes",
    categories: [
      { color: "#E0A00E", imageUrl: melon3, id: "category1" },
      { color: "#EDB405", imageUrl: plant, id: "category2" },
      { color: "#A7D379", imageUrl: carrot, id: "category3" },
      { color: "#593A18", imageUrl: icone_fruit, id: "category4" },
    ],
    url: product4,
  },
  {
    title: "Melância s/ Semente",
    // description: "Some description",
    categories: [
      { color: "#E0A00E", imageUrl: melon3, id: "category1" },
      { color: "#EDB405", imageUrl: plant, id: "category2" },
      { color: "#A7D379", imageUrl: carrot, id: "category3" },
      { color: "#593A18", imageUrl: icone_fruit, id: "category4" },
    ],
    url: product5,
  },
  {
    title: "Melância Preta",
    description: "s/sementes",
    categories: [
      { color: "#E0A00E", imageUrl: melon3, id: "category1" },
      { color: "#EDB405", imageUrl: plant, id: "category2" },
      { color: "#A7D379", imageUrl: carrot, id: "category3" },
      { color: "#593A18", imageUrl: icone_fruit, id: "category4" },
    ],
    url: product6,
  },
  {
    title: "Melão Amarelo",
    // description: "Some description",
    categories: [
      { color: "#E0A00E", imageUrl: melon3, id: "category1" },
      { color: "#EDB405", imageUrl: plant, id: "category2" },
      { color: "#A7D379", imageUrl: carrot, id: "category3" },
      { color: "#593A18", imageUrl: icone_fruit, id: "category4" },
    ],
    url: product1,
  },
  {
    title: "Melão Verde",
    description: "Pele de Sapo",
    categories: [
      { color: "#E0A00E", imageUrl: melon3, id: "category1" },
      { color: "#EDB405", imageUrl: plant, id: "category2" },
      { color: "#A7D379", imageUrl: carrot, id: "category3" },
      { color: "#593A18", imageUrl: icone_fruit, id: "category4" },
    ],
    url: product2,
  },
  {
    title: "Abóbora Butternut",
    // description: "Some description",
    categories: [
      { color: "#E0A00E", imageUrl: melon3, id: "category1" },
      { color: "#EDB405", imageUrl: plant, id: "category2" },
      { color: "#A7D379", imageUrl: carrot, id: "category3" },
      { color: "#593A18", imageUrl: icone_fruit, id: "category4" },
    ],
    url: product10,
  },
];

const modalStyles = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: "12px",
};

function FruitsSlider() {
  const [openModal, setOpenModal] = useState(null);
  const [currentDate, setCurrentDate] = useState(dayjs());
  const [title, setTitle] = useState('');
  // const [newTitles, setNewTitles] = useState('');
  const months = [
    "Jan.",
    "Fev.",
    "Mar.",
    "Abr.",
    "Mai.",
    "Jun.",
    "Jul.",
    "Ago.",
    "Set.",
    "Out.",
    "Nov.",
    "Dez.",
  ];

  const highlightMonths = ["Abr.", "Mai.", "Jun.", "Jul.",];
  const highlightMonthsHarvest = ["Jun.", "Jul.", "Ago.", "Set."];
  const highlightMonthsDisponível = ["Jun.", "Jul.", "Ago.", "Set."];
  const NewhighlightMonthsDisponível = ["Jan.", "Fev.", "Mar.", "Jun.", "Jul.", "Ago.", "Set.", "Out.", "Nov.", "Dez.",];
  const NewhighlightMonthsHarvest = ["Jun.", "Jul.", "Ago.", "Set.", "Out.",];

  const handleMonthClick = (monthIndex) => {
    const newDate = currentDate.month(monthIndex);
    setCurrentDate(newDate);
  };

  const handleOpenModal = (categoryId, fruitTitle) => {
    // setNewTitles(fruitTitle)
    setOpenModal(categoryId);
    setTitle(fruitTitle);
  };

  const handleCloseModal = () => {
    setOpenModal(null);
    // setNewTitles('')
    setTitle('');
  };

  const settings = {
    dots: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: false,
    infinite: true,
    autoplaySpeed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1060,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 575,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const renderContent = () => {
    switch (title) {
      case "Melão Amarelo":
        return {
          calibre: (
            <>
              <p>4/5 (3,3 kg - 5,0kg);</p>
              <p>6/8 (1,5kg - 3,3kg)</p>
            </>
          ),
          forma: "Redonda",
          textura: "Firme",
          cor: (
            <>
              <p>Amarelo</p>
              <p>Polpa branco-esverdeada</p>
            </>
          ),
        };
      case "Melão Branco":
        return {
          calibre: (
            <>
              <p>4/5 (3,3 kg - 5,0kg);</p>
              <p>6/8 (1,5kg - 3,3kg)
              </p>
            </>
          ),
          forma: "Oval/Redonda",
          textura: "Firme",
          cor: (
            <>
              <p>Branca</p>
              <p>Polpa Branca</p>
            </>
          ),
        };
      case "Melão Verde":
        return {
          calibre: (
            <>
              <p>4/5 (3,3 kg - 5,0kg);</p>
              <p>6/8 (1,5kg  3,3kg)</p>
            </>
          ),
          forma: "Oval",
          textura: "Firme",
          cor: (
            <>
              <p>Verde</p>
              <p>Polpa Creme </p>
            </>
          ),
        };

      case "Meloa Cantaloupe":
        return {
          calibre: (
            <>
              <p>4/8 (1,1kg - 2kg); 5/10 (0,9kg - 1,1kg);</p>
              <p>6/12 (0,8kg - 0,9kg); 7/14 (0,7kg - 0,8kg)</p>
              <p>8/16 (0,5kg - 0,7kg)</p>
            </>
          ),
          forma: "Redonda",
          textura: (
            <>
              <p>Firme</p>
            </>
          ),
          cor: (
            <>
              <p>Verde/ Amarela</p>
              <p>Polpa Laranja/Salmao</p>
            </>
          ),
        };

      case "Meloa Gália":
        return {
          calibre: (
            <>
              <p>4/8 (1,1kg - 2,0kg); 5/10 (0,9kg - 1,1kg);</p>
              <p>6/12 (0,8kg - 0,9kg); 7/14 (0,7kg - 0,8kg)</p>
              <p>8/16 (0,5kg - 0,7kg)</p>
            </>
          ),
          forma: "Redonda",
          textura: "Firme",
          cor: (
            <>
              <p>Verde/ Amarela</p>
              <p>Polpa branco-esverdeada</p>
            </>
          ),
        };

      case "Melância Preta":
        return {
          calibre: (
            <>
              <p>2/3 (5,7kg - 10,0kg); 4/5 (3,7kg - 5,7kg);</p>
              <p>6 (2,0kg - 3,7kg)</p>
            </>
          ),
          forma: "Redonda",
          textura: "Firme",
          cor: (
            <>
              <p>Verde-escuro</p>
              <p>Polpa vermelha intensa</p>
              (Pode conter algumas sementes,mas pouco
              desenvolvidas, com embriões brancos e macios,
              totalmente comestíveis)
            </>
          ),
        };

      case "Melância Riscada":
        return {
          calibre: (
            <>
              <p>2/3 (5,7kg - 10,0kg); 4/5 (3,7kg - 5,7kg);</p>
              <p>6/8 (2,0kg - 3,7kg)</p>
            </>
          ),
          forma: "Redonda",
          textura: "Firme ",
          cor: (
            <>
              <p>Riscada Verde Claro-Verde Escuro</p>
              <p>Polpa Vermelha</p>
              (As suas sementes são desenvolvidas,
              mas de tamanho reduzido)
            </>
          ),
        };

      case "Melância s/ Semente":
        return {
          calibre: (
            <>
              <p>2 (8kg - 10kg); 3 (5,7kg - 8kg);</p>
              <p>4 (4,5kg - 5,7kg); 5 (3,7kg - 4,5kg)</p>
            </>
          ),
          forma: "Oval",
          textura: "Fina",
          cor: (
            <>
              <p>Verde por fora </p>
              <p>Vermelho por dentro</p>
            </>
          ),
        };

      case "Abóbora Butternut":
        return {
          calibre: (
            <>
              <p>6/7 (2,00kg - 1,40kg); 8/9 (1,40kg - 1,10kg);</p>
              <p>10/11 (0,90kg - 1,10kg);</p>
              <p>12/13 (0,80kg - 0,90kg)</p>
            </>
          ),
          forma: (
            <>
              <p>Cabaça</p>
              (típica da variedade)
            </>

          ),
          textura: "Firme",
          cor: (
            <>
              <p>Bege</p>
              <p>Polpa Laranja</p>
            </>
          ),
        };

      default:
        return {
          calibre: (
            <>
              <p>4/8 (1,1kg - 2kg); 5/10 (0,9kg - 1,1kg);</p>
              <p>6/12 (0,8kg - 0,9kg); 7/14 (0,7kg - 0,8kg)</p>
            </>
          ),
          forma: "Oval",
          textura: "Firme",
          cor: (
            <>
              <p>Verde/ Amarela</p>
              <p>Polpa branco-esverdeada</p>
            </>
          ),
        };
    }
  };

  const { calibre, forma, textura, cor } = renderContent();

  return (
    <div className="FruitsSlider" id={'products'}>
      <div className="container">
        <div className="text-center">
          <p> Produtos </p>
          <h3 className="section-title">
            Produtos e <span> especialidades</span>
          </h3>
        </div>
        <div className="FruitsSliderBox">
          <Slider {...settings}>
            {fruitsData.map((fruit, index) => (
              <div key={index}>
                <div className="fruitsSlide">
                  <div className="fruitsSlide__img">
                    <img src={fruit.url} alt={fruit.title} />
                  </div>
                  <div className="fruitsSlide__content">
                    <h4 className="fruitsSlide__title">{fruit.title}<span>{fruit.description}</span></h4>
                    <div className="fruitscategory">
                      {fruit.categories.map((category) => (
                        <a
                          key={category.id}
                          className="fruitscategory__inner"
                          href="#"
                          style={{ backgroundColor: category.color }}
                          onClick={(e) => {
                            e.preventDefault();
                            handleOpenModal(category.id, fruit.title);
                          }}
                        >
                          <img src={category.imageUrl} alt="" />
                        </a>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </Slider>
        </div>
      </div>

      {/* Modals */}
      <Modal
        open={openModal === "category1" || (title && title === "Melão Amarelo")}
        onClose={handleCloseModal}
        className="custom_modal"
      >
        <Box sx={modalStyles}>
          <div className="FruitsBoxModel">
            <div className="FruitsBox">
              <div className="FruitsBox__icon">
                <img src={Icon1} alt="" />
              </div>
              <div className="FruitsBox__content">
                <h2>Calibre</h2>
                {calibre}
              </div>
            </div>
            <div className="FruitsBox">
              <div className="FruitsBox__icon">
                <img src={Icon2} alt="" />
              </div>
              <div className="FruitsBox__content">
                <h2>Forma</h2>
                <p>{forma}</p>
              </div>
            </div>
            <div className="FruitsBox">
              <div className="FruitsBox__icon">
                <img src={Icon3} alt="" />
              </div>
              <div className="FruitsBox__content">
                <h2>Textura</h2>
                <p>{textura}</p>
              </div>
            </div>
            <div className="FruitsBox">
              <div className="FruitsBox__icon">
                <img src={Icon4} alt="" />
              </div>
              <div className="FruitsBox__content">
                <h2>Cor</h2>
                {cor}
              </div>
            </div>
          </div>
        </Box>
      </Modal>

      <Modal
        open={openModal === "category2"}
        onClose={handleCloseModal}
        className="custom_modal CateoryCalendar CateoryFirst"
      >
        <Box sx={modalStyles}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DemoContainer
              components={["DateCalendar", "DateCalendar", "DateCalendar"]}
            >
              <h2 className="title">Plantação</h2>
              <div className="monthsContainer" style={styles.monthsContainer}>
                {months.map((month, index) => {
                  const isHighlighted = highlightMonths.includes(month);
                  return (
                    <div
                      key={month}
                      style={{
                        ...styles.month,
                        color: isHighlighted ? "#fff" : "#555",
                        backgroundColor: isHighlighted
                          ? "#EDB405"
                          : "transparent",
                        fontWeight: isHighlighted ? "bold" : "normal",
                      }}
                      onClick={() => handleMonthClick(index)}
                    >
                      {month}
                    </div>
                  );
                })}
              </div>
            </DemoContainer>
          </LocalizationProvider>
        </Box>
      </Modal>

      <Modal
        open={openModal === "category3"}
        onClose={handleCloseModal}
        className="custom_modal CateoryCalendar CateorySecond"
      >
        <Box sx={modalStyles}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DemoContainer
              components={["DateCalendar", "DateCalendar", "DateCalendar"]}
            >
              <h2 className="title">Colheita</h2>
              <div style={styles.monthsContainer}>
                {months.map((month, index) => {
                  // const isHighlighted = highlightMonthsHarvest.includes(month);
                  const isHighlighted = title === 'Abóbora Butternut' ? NewhighlightMonthsHarvest.includes(month) : highlightMonthsHarvest.includes(month);
                  return (
                    <div
                      key={month}
                      style={{
                        ...styles.month,
                        color: isHighlighted ? "#fff" : "#555",
                        backgroundColor: isHighlighted
                          ? "#A7D379"
                          : "transparent",
                        fontWeight: isHighlighted ? "bold" : "normal", // Bold font weight for highlighted months
                      }}
                      onClick={() => handleMonthClick(index)}
                    >
                      {month}
                    </div>
                  );
                })}
              </div>
            </DemoContainer>
          </LocalizationProvider>
        </Box>
      </Modal>

      {/* <Modal
        open={openModal === "category4"}
        onClose={handleCloseModal}
        className="custom_modal CateoryCalendar CateoryThird"
      >
        <Box sx={modalStyles}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DemoContainer
              components={["DateCalendar", "DateCalendar", "DateCalendar"]}
            >
              <h2 className="title">Disponível</h2>
              <div style={styles.monthsContainer}>
                {months.map((month, index) => {
                  debugger
                  const isHighlighted = highlightMonthsDisponível.includes(month);
                  return (
                    <div
                      key={month}
                      style={{
                        ...styles.month,
                        color: isHighlighted ? "#fff" : "#555",
                        backgroundColor: isHighlighted
                          ? "#593A18"
                          : "transparent",
                        fontWeight: isHighlighted ? "bold" : "normal", // Bold font weight for highlighted months
                      }}
                      onClick={() => handleMonthClick(index)}
                    >
                      {month}
                    </div>
                  );
                })}
              </div>
            </DemoContainer>
          </LocalizationProvider>
        </Box>
      </Modal> */}

      <Modal
        open={openModal === "category4"}
        onClose={handleCloseModal}
        className="custom_modal CateoryCalendar CateoryThird"
      >
        <Box sx={modalStyles}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DemoContainer components={["DateCalendar", "DateCalendar", "DateCalendar"]}>
              <h2 className="title">Disponível</h2>
              <div style={styles.monthsContainer}>
                {months.map((month, index) => {
                  const isHighlighted = title === 'Abóbora Butternut' ? NewhighlightMonthsDisponível.includes(month) : highlightMonthsDisponível.includes(month);
                  return (
                    <div
                      key={month}
                      style={{
                        ...styles.month,
                        color: isHighlighted ? "#fff" : "#555",
                        backgroundColor: isHighlighted
                          ? "#593A18"
                          : "transparent",
                        fontWeight: isHighlighted ? "bold" : "normal", // Bold font weight for highlighted months
                      }}
                      onClick={() => handleMonthClick(index)}
                    >
                      {month}
                    </div>
                  );
                })}
              </div>
            </DemoContainer>
          </LocalizationProvider>
        </Box>
      </Modal>
    </div>
  );
}

const styles = {
  container: {
    textAlign: "center",
    fontFamily: "Arial, sans-serif",
  },
  title: {
    color: "#F5A623", // Yellow color
    fontSize: "36px",
    fontWeight: "bold",
  },
  currentMonth: {
    margin: "20px 0",
    fontSize: "18px",
    color: "#333",
  },
  monthsContainer: {
  },
  month: {
    margin: "20px",
    padding: "10px",
    cursor: "pointer",
    fontSize: "16px",
    width: "calc(25% - 20px)",
    textAlign: "center",
    height: "70px",
    width: "70px",
    display: "flex",
    justifyContent: "center",
    borderRadius: "18px",

  },
};
export default FruitsSlider;
