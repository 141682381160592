import React from "react";
import Grid from "@mui/material/Grid";
import email from "../../../src/Asset/svg/emial-icon.svg";
import phone from "../../../src/Asset/svg/phone-icon.svg";
import location from "../../../src/Asset/svg/location.svg";
import "./contactUs.scss";

function ContactUs() {
  return (
    <>
      <div id="contact" className="contactUs">
        <div className="container">
          <Grid container spacing={{ md: 10, xs: 6 }}>
            <Grid item xs={12} lg={6}>
              <div className="contact_details">
                {/* <h4 className="subtitle">Entre em contacto</h4> */}
                <h2 className="title">Entre em contacto</h2>
                <p>
                  Entre em contacto para qualquer dúvida <br />
                  ou informação adicional.
                </p>
                <div className="details_card">
                  <div className="icons">
                    <img src={phone} alt="phone" />
                  </div>
                  <div className="containt">
                    <span>Contactos telefónicos</span>
                    <p>
                      <a href="">+351 913 590 040</a>
                    </p>
					  <p>
                      <a href="">+351 243 429 681</a>
                    </p>
                  </div>
                </div>
                <div className="details_card">
                  <div className="icons">
                    <img src={email} alt="email" />
                  </div>
                  <div className="containt">
                    <span>Email</span>
                    <p>hortoriba@hortoriba.pt</p>
                  </div>
                </div>
                <div className="details_card">
                  <div className="icons">
                    <img src={location} alt="location" />
                  </div>
                  <div className="containt">
                    <span>Morada</span>
                    <p>
                      Rua da Liberdade, nº 117 Verdelho, <br />
                      2000-345 Santarém <br />
                      {/* Coordenadas: 39.31749990632901, - 8.688752781453823 */}
                    </p>
                  </div>
                </div>
              </div>
            </Grid>
            <Grid item xs={12} lg={6}>
              <form className="form_block">
                <div className="input_box">
                  <div className="form_block__form-control">
                    <label className="label" htmlFor="">
                      Nome Completo
                    </label>
                    <input type="text" name="" id="" />
                  </div>
                  <div className="form_block__form-control">
                    <label className="label" htmlFor="">
                      Email
                    </label>
                    <input type="text" name="" id="" />
                  </div>
                </div>
                <div className="form_block__form-control">
                  <label className="label" htmlFor="">
                    Mensagem
                  </label>
                  <textarea rows="6" name="" id=""></textarea>
                </div>
                <button className="contact-btn">Contactar</button>
              </form>
            </Grid>
          </Grid>
        </div>
      </div>
    </>
  );
}

export default ContactUs;
